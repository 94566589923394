import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../slices/user/userSlice";
import languageReducer from "../slices/language/languageSlice";

export default configureStore({
  reducer: {
    user: userReducer,

    language: languageReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: false,
});
