import axios from "axios";
import { domain, URL, VIKNBOOKS_API_URL } from "../settings";
import { Decrypt, Encrypt, getCookie } from "./utils";
import Cookies from "universal-cookie";

const _ = require("lodash");
const cookies = new Cookies();

export const set_RecentLoginUsersCookies = (data) => {
  let ciphertext = Encrypt(data);
  // =======END=============
  axios
    .get(`${URL}api/v1/accounts/recent-login-users?data=${ciphertext}`, {
      withCredentials: true,
    })
    .then(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = params.service;
    });
};

export const set_handleLogout = (dic) => {
  document.cookie = `VBID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;

  // ==================RECENT USERS==================
  let recent_login_users = getCookie("ACCOUNT_CHOOSER");
  let data = [];
  let data_exists = {};
  try {
    if (recent_login_users) {
    data = Decrypt(JSON.parse(recent_login_users));
    data_exists = _.find(data, function (o) {
      return o.username == dic["username"];
    });
  }
  } catch {
    console.log("exception");
  }
  

  if (_.isEmpty(data_exists)) {
    data.push(dic);
    console.group("^^^^^IFFF^^^^^^^^^^^&*");
  } else {
    console.group("^^^^^ELSE^^^^^^^^^^^&*");
  }

  data = data.slice(-2);
  set_RecentLoginUsersCookies(data);
};

export const get_unq_browserID = async (name) => {
  if (name === "device-uid") {
    // const biri = require("biri");
    // const uniqueId = await biri();
    // -------------------------------------
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    console.log(uid);
    return uid;
  } else {
    // const uuid = new DeviceUUID().get();
    const uqNo = 12345;
    return uqNo;
  }
};

export const set_BrowserUnqCookies = (uqNo) => {
  axios
    .get(`${VIKNBOOKS_API_URL}users/set-browser-uqid?data=${uqNo}`, {
      withCredentials: true,
    })
    .then(() => {
      console.log("cookie set successfully");
    });
};
export const getCurrentDetails = async () => {
  const currentDetails = await fetch("https://ipapi.co/json/", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  }).then((response) => response.json());

  document.cookie = `city=${currentDetails.city}; expires=Thu, 01 Jan 2024 00:00:00 UTC; path=/;domain=${domain}`;
  document.cookie = `country=${currentDetails.country_name}; expires=Thu, 01 Jan 2024 00:00:00 UTC; path=/;domain=${domain}`;
  document.cookie = `region=${currentDetails.region}; expires=Thu, 01 Jan 2024 00:00:00 UTC; path=/;domain=${domain}`;
};

export const getFileUrl = async (file) => {
  try {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
    return src;
  } catch {
    return "";
  }
};

export const dataURLtoFile = async (dataurl) => {
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  console.log(response);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  return file;
};
