import React, { lazy, Suspense } from "react";
import styled from "styled-components/macro";

import { Link, NavLink, Route, Routes as Switch } from "react-router-dom";
import { ButtonBase, CircularProgress } from "@mui/material";
import Header from "../components/Header/Header";
import SideBar from "../components/SideBar/SideBar";

const PaymentSubscription = lazy(() =>
  import("../pages/Screens/PaymentSubscription1")
);

const Dashboard = lazy(() => import("../components/Dashboard/Dashboard"));
const AccountInfo = lazy(() => import("../pages/Screens/AccountInfo"));
const ChangeOwnership = lazy(() => import("../pages/Screens/ChangeOwnership"));
const DeleteOrganisation = lazy(() =>
  import("../pages/Screens/DeleteOrganisation")
);

const Security = lazy(() => import("../pages/Screens/Security"));
const Personalinfo = lazy(() => import("../pages/Screens/Personalinfo"));

const Navigation = () => {
  const image_root = window.location.origin;

  return (
    <Container>
      <Header />

      <MenuContainer>
        <SideBar />
        <Suspense
          fallback={
            <Box>
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            <Switch>
              <Route path="/" element={<Personalinfo />} />
              <Route
                path="/payment-subscription"
                element={<PaymentSubscription />}
              />
              <Route path="/security" element={<Security />} />
              <Route path="/account-info" element={<AccountInfo />} />
              <Route
                path="/change-ownership/:companyid"
                element={<ChangeOwnership />}
              />
              <Route
                path="/delete-organisation"
                element={<DeleteOrganisation />}
              />

              {/* <Route path="/help" element={<Help />} /> */}
            </Switch>
          </Routes>
        </Suspense>
      </MenuContainer>
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  background-image: linear-gradient(22deg, #ecf2f0, #f5f8f7);
  padding-bottom: 10px;
  /* width: 100vw; */
  min-height: 100vh;
`;

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* padding-bottom: 10px; ; */
`;
const Routes = styled.div`
  /* width: 86.4%; */
  margin: 0;
  /* margin-left: 67px; */
  margin-top: 67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 15px;
  /* background-color: #ffffff; */
  /* min-height: 100vh; */
  border-radius: 6px;
`;

const Box = styled.div`
  width: 100%;
  height: 80vh;

  /* width: 96%; */

  display: grid;
  place-items: center;
`;
