import axios from "axios";

//localhost
// export const URL = "http://localhost:8000/";
// export const BASE_URL = "http://localhost:8000/api/v1/";
// export const MEDIA_URL = "http://localhost:8000";
// export const TASK_API_URL = "http://localhost:8001/";
// export const TASK_FRONT_URL = "http://localhost:3003/";
// export const ADMIN_FRONT_URL = "http://localhost:3001/";
// export const VIKNBOOKS_API_URL = "http://localhost:8002/api/v10/";
// export const VIKNBOOKS_API_BASEURL = "http://localhost:8002/";
// export const VIKNBOOKS_FRONT_URL = "http://localhost:3002/";
// export const PAYROLL_FRONT_URL = "http://localhost:3003/";
// export const TOKEN_FRONT_URL = "http://localhost:5173/";
// export const SIGNIN_URL = axios.create({
//   withCredentials: true,
//   baseURL: "http://localhost:8000/api/v1",
// });
// export const domain = "localhost";

//.in server url start from here
// export const URL = "https://api.accounts.vikncodes.in/";
// export const BASE_URL = "https://api.accounts.vikncodes.in/api/v1/";
// export const MEDIA_URL = "https://api.accounts.vikncodes.in";
// export const TASK_API_URL = "https://api.task.vikncodes.in/";
// export const TASK_FRONT_URL = "https://task.vikncodes.in/";
// export const VIKNBOOKS_API_URL = "https://www.api.viknbooks.in/api/v10/";
// export const VIKNBOOKS_API_BASEURL = "https://www.api.viknbooks.in/";
// export const VIKNBOOKS_FRONT_URL = "https://www.viknbooks.in/";
// export const PAYROLL_FRONT_URL = "https://payroll.vikncodes.in/";
// export const TOKEN_FRONT_URL = "https://tokenplus.vikncodes.in/";
// export const ADMIN_FRONT_URL = "https://admin.vikncodes.in/";
// export const SIGNIN_URL = axios.create({
// withCredentials: true,
//   baseURL: "https://api.accounts.vikncodes.in/api/v1/",
// });
// export const domain = "vikncodes.in";

//.com server url start from here
export const URL = "https://api.accounts.vikncodes.com/";
export const BASE_URL = "https://api.accounts.vikncodes.com/api/v1/";
export const MEDIA_URL = "https://api.accounts.vikncodes.com";
export const TASK_API_URL = "https://api.task.vikncodes.com/";
export const TASK_FRONT_URL = "https://task.vikncodes.com/";
export const VIKNBOOKS_API_URL = "https://www.api.viknbooks.com/api/v10/";
export const VIKNBOOKS_API_BASEURL = "https://www.api.viknbooks.com/";
export const COOKIE_API_URL = "https://www.api.viknbooks.com/api/v10/";
export const ADMIN_FRONT_URL = "https://admin.vikncodes.com/";
export const VIKNBOOKS_FRONT_URL = "https://viknbooks.com/";
export const TOKEN_FRONT_URL = "https://tokenplus.vikncodes.com/";
export const PAYROLL_FRONT_URL = "https://payroll.vikncodes.com/";
export const SIGNIN_URL = axios.create({
  baseURL: "https://api.accounts.vikncodes.com/api/v1/",
});
export const domain = "vikncodes.com";
