import { ButtonBase } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ContextData } from "../Context1/Context1";
import { useTranslation } from "react-i18next";
function SideBar() {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const { SideMenu } = useContext(ContextData);
  const [showMenu, setShowMenu] = SideMenu;

  const image_root = window.location.origin;
  console.log("image", image_root);
  const MenuList = [
    {
      label: t("Personal Info"),
      image: image_root + "/images/icons/user-profile.svg",
      linkList: "/",
      //   linkCreate: "",
    },
    {
      label: t("Security"),
      image: image_root + "/images/icons/security.svg",
      linkList: "/security",
      //   linkCreate: () => setProject(true),
    },
    {
      label: t("Payment & Subscription"),
      image: image_root + "/images/icons/credit-card-line.svg",
      linkList: "/payment-subscription",
      //   linkCreate: () => setTask(true),
    },

    {
      label: t("Account Info"),
      image: image_root + "/images/icons/account.svg",
      linkList: "/account-info",
    },
  ];

  const { pathname } = useLocation();

  // const GotCreate = (link) => {
  //   console.log(link);
  //   navigate(`${link}`, { state: { create: true } });
  // };

  return (
    <Container showMenu={showMenu}>
      <>
        <SideBarMenus>
          {MenuList.map((i, index) => (
            <Menu key={index}>
              <ButtonBase style={{ width: "100%" }}>
                <MenuIconContainer>
                  <StyledNavLink
                    onClick={() => setShowMenu(false)}
                    to={i.linkList}
                    className={
                      [i.linkList, i.linkCreate].includes(pathname)
                        ? "active"
                        : ""
                    }
                  >
                    <MenuIconImg src={i.image} alt="label" />
                    <MenuText>{i.label}</MenuText>
                  </StyledNavLink>
                </MenuIconContainer>
              </ButtonBase>
            </Menu>
          ))}
        </SideBarMenus>
      </>

      <BottomContainer>
        <PrivacyTxt onClick={() => navigate("/privacy-policy/:1/")}>
          {t("Privacy Policy")}
        </PrivacyTxt>
        <PrivacyTxt onClick={() => navigate("/privacy-policy/:2/")}>
          {t("Terms and Conditions")}
        </PrivacyTxt>
      </BottomContainer>
    </Container>
  );
}

export default SideBar;

const PrivacyTxt = styled.span`
  cursor: pointer;
`;
const BottomContainer = styled.div`
  color: #727272;
  font-size: 11px;
  padding: 20px;
  width: 93%;

  display: flex;
  justify-content: space-between;
`;

const SideBarMenus = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const CreateButton = styled(Button)`
  display: flex;
  margin-left: auto;
  padding: 0 10px;
  svg {
    transition: all 0.2s ease-in-out;
    color: #b8becb;
  }
  &&.active,
  &&:hover {
    svg {
      transition: all 0.2s ease-in-out;
      color: #000 !important;
    }
  }
`;
const MenuIconContainer = styled.div`
  font-family: "poppins", sans-serif !important;
  margin: 0 auto;
  border-radius: 22px 22px 22px 22px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.1s ease-in-out;
  &&.active {
    background-color: white;
  }
`;

const MenuText = styled.p`
  white-space: nowrap;
  color: #373737;
  font-size: 14px;
  margin: 8px 1px 8px 10px;

  /* margin-top: unset !important; */

  transition: all 0.1s ease-in-out;
`;
const MenuIconImg = styled.img`
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
  &&.active {
    color: black;
  }

  && path {
    fill: #fff !important;
  }
`;

const StyledNavLink = styled(NavLink)`
  padding: 8px 8px 8px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;

  &&:hover,
  &&.active {
    ${MenuIconContainer} {
      background: #e8e8e8;

      transition: all 0.2s ease-in-out;
      /* background-color: white; */
    }
    ${MenuIconImg} {
      transition: all 0.2s ease-in-out;
      filter: brightness(0);

      color: black;
    }
    ${MenuText} {
      transition: all 0.2s ease-in-out;
      color: #373737;
    }
    ${CreateButton} {
      svg {
        transition: all 0.2s ease-in-out;
        color: #000 !important;
      }
      color: #000 !important;
    }
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 0px 3px 0px #0000004f; */
    background-color: #e8e8e8;
    border-radius: 22px 22px 22px 22px;
  }
`;

const Menu = styled.div`
  && {
    cursor: pointer;
    text-decoration: none;
    margin-top: 10px;
  }
  .MuiButtonBase-root {
    border-radius: 15px;
  }
  &&.active,
  &&:hover {
    ${MenuIconContainer} {
      /* background: #ffffff 0% 0% no-repeat padding-box; */

      background: #e8e8e8;
      box-shadow: 0px 0px 3px 0px #0000004f;
      transition: all 0.2s ease-in-out;
    }
    ${MenuIconImg} {
      transition: all 0.2s ease-in-out;
      filter: brightness(0);
      /* filter: brightness(0) invert(1); */
    }
    ${MenuText} {
      transition: all 0.2s ease-in-out;
      color: #373737;
    }
    ${CreateButton} {
      svg {
        transition: all 0.2s ease-in-out;
        color: #000 !important;
      }
    }
  }
`;

const Logos__Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: contain;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
`;
const Container = styled.div`
  padding: 0px 10px 0px 10px;
  margin: 67px 5px 0px 11px;
  height: 92vh;

  position: sticky;
  left: 0;
  border-radius: 29px 29px 29px 29px;
  max-width: 274px;
  width: 23vw;
  background-color: #ffffff;
  min-height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  @media (max-width: 1008px) {
    position: absolute;
    display: ${({ showMenu }) => (showMenu ? "block" : "none")};
    margin: auto;
    right: 0;
    top: 64px;
    width: 94vw;
    z-index: 1;
    max-width: 954px;
  }
`;
