import * as base from "../settings";

export const loginUrl = base.BASE_URL + "users/user-login/";
export const loginAdminsUrl = base.BASE_URL + "users/user-login/admins/";
export const signupUrl = base.BASE_URL + "users/user-signup";
export const logoutUrl = base.BASE_URL + "users/logout-view/";
export const defaultValueUrl = base.BASE_URL + "users/get-default-values/";
export const checkUsernameUrl = base.BASE_URL + "users/check-username/";
export const forgotPasswordUrl = base.BASE_URL + "users/forgot-password/";
export const forgotPasswordConfirmUrl =
  base.BASE_URL + "users/forgot-password-confirm/";
export const signupVerificationUrl = base.BASE_URL + "users/signup-verified/";
export const resendVarificationUrl =
  base.BASE_URL + "users/resend-verification-code/";
export const changePasswordUrl = base.BASE_URL + "users/change-password/";

export const getUserDatas = base.BASE_URL + "users/get-user/datas/";
export const profile_update_url = base.BASE_URL + "users/profile-update/";
export const feddback_url = base.BASE_URL + "users/send-feedback/";

export const payment_suscriptionUrl =
  base.BASE_URL + "accounts/payments-subscription";


