import { useEffect } from "react";
import SimpleCrypto from "simple-crypto-js";

export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}

function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log(false);
        // alert("You clicked outside of me!");
      }
      return false;
    }
  }, [ref]);
}

export function stringCamelCase(string) {
  var split_string = string.split(" ");

  let final_arr = [];
  let final_name = "";

  for (let index = 0; index < split_string.length; index++) {
    const element = split_string[index];
    let name = element.charAt(0).toUpperCase() + element.slice(1);
    console.log(name);
    if (final_name) {
      final_name += " " + String(name);
    } else {
      final_name = String(name);
    }
    final_arr.push(final_name);
  }
  var lastItem = final_arr.pop();

  return lastItem;
}
// truncate number of words by passing the paragraph and number
export function truncate(str, num = 12) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
// to check the list of values is present in the array
export const listChecker = (arr, target) =>
  target.every((v) => arr.includes(v));

export const getInitials = function (string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
// to get index value from a array by passing array by passing the list of values and number
export const get_index = (list, id) => {
  let index = null;
  if (list) {
    // index = list.findIndex((i) => console.log(i.id, "FIXING BUG", id));
    index = list.findIndex((i) => i.id === id);
  }
  return index;
};

// convert datetime object to python date string
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const findDuplicate = (array1, array2) => {
  var duplicates = array1.filter((val) => array2.indexOf(val) !== -1);
  return duplicates;
};

// just pass the id to show the password
export const showPassword = (id) => {
  var x = document.getElementById(id);
  console.log(x);
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
};

// download Excel
export function download_excel(url) {
  var element = window.document.createElement("a");
  element.href = url;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// date to string(today)
export function today_date() {
  var today = new Date();
  today.setDate(today.getDate());
  var Today = today.toISOString().substr(0, 10);
  return Today;
}

// to get age pass date of birth
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
// to get avatar images just pass a value you will get a random image
export const getAvatar = (value = "1", imageType = "identicon", res = 46) => {
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);

  // Create an hash of the string
  const hash = hashCode(value);
  // Grab the actual image URL

  // return `https://avatars.dicebear.com/api/${imageType}/${hash}.svg`;
  return `https://www.gravatar.com/avatar/${hash}?s=${res}&d=${imageType}&r=PG&f=1`;
};

export const get_Transaction_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TransactionTypesID === id);
  }
  return index;
};

// check image exists
export const imageExists = (image_url) => {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status != 404;
};

export const dataURLtoFile = async (dataurl) => {
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  console.log(response);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  return file;
};

// get date n days ahead date react js hook
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  let Year = result.getFullYear();
  let Month = result.getMonth() + 1;
  let Day = result.getDate();
  Day = ("0" + Day).slice(-2);
  Month = ("0" + Month).slice(-2);
  result = String(Year) + "-" + String(Month) + "-" + String(Day);
  return result;
};

export const get_fileName_fromPath = (file) => {
  return file.split("\\").pop().split("/").pop();
};

export const get_HashValue = (data = "Hello World!", type = "encrypt") => {
  var simpleCrypto = new SimpleCrypto("9400447304");

  if (type === "encrypt") {
    var hashvalue = simpleCrypto.encrypt(data);
  } else {
    var hashvalue = simpleCrypto.decrypt(data);
  }

  return hashvalue;
};

export const getServiceUrl = (url) => {
  var b = url.substring(url.indexOf("?") + 1);
  return b.substring(b.indexOf("=") + 1);
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const setRecentUsersCookie = (dic) => {
  // var CryptoJS = require("crypto-js");
  // var ciphertext = CryptoJS.AES.encrypt(
  //   JSON.stringify(dic),
  //   "my-secret-key@123"
  // ).toString();
  let ciphertext = Encrypt(dic);
  return ciphertext;
};
export const getDecryptedData = (ciphertext) => {
  let decryptedData = Decrypt(ciphertext);
  console.log("typeof", ciphertext, "ULLIL>>>>>>>>>>");
  // console.log(typeof ciphertext, "ULLIL>>>>>>>>>>");
  // var CryptoJS = require("crypto-js");
  // var bytes = CryptoJS.AES.decrypt(ciphertext, "my-secret-key@123");
  // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};
export function Encrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}
export function Decrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(bytes);
}

export function containsWhitespace(str) {
  return /\s/.test(str);
}

export function EncryptBase64(data) {
  var encoded = btoa(JSON.stringify(data));
  return encoded;
}

export function getUnqNo() {
  return Date.now();
}

// View base64URL
export function debugBase64(base64URL) {
  let url =
    base64URL +
    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>';
  console.log(url);
  return url;
}
