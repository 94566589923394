import "./App.css";
import React, { lazy, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from "react-router-dom";

import { CircularProgress } from "@mui/material";
import styled from "styled-components/macro";

import CacheBuster from "./CacheBuster";
import Navigation from "./Routes/Navigation";

// const Navigation = lazy(() => import("./pages/Routes/Navigation"));
const SignIn = lazy(() => import("./pages/Auth/SignIn"));
const SignUp = lazy(() => import("./pages/Auth/SignUp"));
// const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ForgotPassword1 = lazy(() => import("./pages/Auth/ForgotPassword1"));
const ForgotPasswordConfirm = lazy(() =>
  import("./pages/Auth/ForgotPasswordConfirm")
);
const SignUpVerification = lazy(() =>
  import("./pages/Auth/SignUpVerification")
);
const EmailVerification = lazy(() => import("./pages/Auth/EmailVerification"));
const SendVerification = lazy(() => import("./pages/Auth/SendVerification"));

const ResetPassWord = lazy(() => import("./pages/Auth/ResetPassWord"));
const ForgotPassword_OTP = lazy(() =>
  import("./pages/Auth/ForgotPassword_OTP")
);
const AccountDeletion = lazy(() =>
  import("../src/pages/Screens/AccountDeletion")
);

const PrivacyPolicy = lazy(() => import("./pages/Screens/PrivacyPolicy"));
const Whatsnew = lazy(() => import("./pages/Screens/Whatsnew"));
const ChangeOwnership = lazy(() => import("./pages/Screens/ChangeOwnership"));
const DeleteOrganisation = lazy(() =>
  import("./pages/Screens/DeleteOrganisation")
);
const DeactivateApplication = lazy(() =>
  import("./pages/Screens/DeactivateApplication")
);

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <div className="App">
            <Suspense
              fallback={
                <Box>
                  <CircularProgress />
                </Box>
              }
            >
              <Router>
                <Switch>
                  <Route path="/whatsnew" element={<Whatsnew />} />

                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                  {/* Abhinand ========================================================================== */}
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassword1 />}
                  />
                  <Route path="/forgot-otp" element={<ForgotPassword_OTP />} />
                  <Route path="/reset-password" element={<ResetPassWord />} />
                  {/* Abhinand ========================================================================== */}
                  <Route
                    path="/resend-verification"
                    element={<SendVerification />}
                  />
                  {/* <Route
                  path="/forgot-password-confirm/:uidb64/:token"
                  element={<ForgotPasswordConfirm />}
                /> */}
                  <Route
                    path="/forgot-password-confirm/:uidb64"
                    // element={<ForgotPasswordConfirm />
                    element={<ResetPassWord />}
                  />
                  <Route
                    path="/signUp-verification/:id/:token/:service"
                    element={<SignUpVerification />}
                  />
                  <Route
                    path="/email-verification/"
                    element={<EmailVerification />}
                  />
                  {/* <Route
                  path="/create-company"
                  element={<CreateOrganization />}
                /> */}
                  <Route path="/*" element={<Navigation />} />

                  {/* Abhinand ========================================================================== */}
                  <Route
                    path="/account-deletion"
                    element={<AccountDeletion />}
                  />
                  <Route path="/privacy-policy/:1" element={<PrivacyPolicy />} />

                  <Route path="/privacy-policy/:2" element={<PrivacyPolicy />} />
                  <Route
                    path="/change-ownership"
                    element={<ChangeOwnership />}
                  />
                  <Route
                    path="/delete-organisation/:id"
                    element={<DeleteOrganisation />}
                  />
                  <Route
                    path="/deactivate-application/:service"
                    element={<DeactivateApplication />}
                  />
                  {/* Abhinand ========================================================================== */}
                </Switch>
              </Router>
            </Suspense>
          </div>
        );
      }}
    </CacheBuster>
  );
}

export default App;
const Box = styled.div`
  width: 96%;
  height: 80vh;
  display: grid;
  place-items: center;
`;
