import React, { createContext, useState } from "react";
export const ContextData = createContext();
function Context1({ children }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <ContextData.Provider
      value={{
        SideMenu: [showMenu, setShowMenu],
      }}
    >
      {children}
    </ContextData.Provider>
  );
}

export default Context1;
